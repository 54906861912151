class Transcript {
    /**
     * Shows the given receiver on the console.
     * 
     * @param {string} aString 
     */
	static show(aString) {
		console.log(aString);
	}	
}

export default Transcript;