import React, { Component } from 'react';
import "./Header.css";

import HeaderBanner from "./HeaderBanner";
import NavigationBar from './navigation/NavigationBar';

export default class Header extends Component {
    render() {
      return (
        <header className="header">
            <HeaderBanner></HeaderBanner> 
            <NavigationBar></NavigationBar>           
        </header>      
      );  
    }
  }