import React, { Component } from 'react';

import "./ContactSection.css";

export default class ContactSection extends Component {
    render() {
      return (
        
        <section className="contactsection">     
            <a id="kontakt" href="."> </a>
            <h1>Kontakt</h1> 
            

            <div className="row contactPart"> 
                        <div className="col-sm">
                            <img src="images/icons/icon-384x384.png" alt="Logo"/>  
                        </div>           
                        <div className="col-sm">
                            <h2>So erreichen Sie uns</h2>       
                            <div>
                                <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="row">Adresse</th>
                                        <td>
                                            <address className="vcard">
                                            <div className="fn org">Bahn-Hofladen</div>
                                            <div className="adr">
                                                <div className="street-address">Am Bahnhof 3, OT Rottenbach</div>
                                                <div className="locality">Königsee</div>  
                                                <div className="region"></div>
                                                <div className="postal-code">07426</div>
                                                <div className="country-name">Deutschland</div>
                                            </div>
                                            </address>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><span className="fa fa-phone"/>&nbsp;Telefon</th>
                                        <td>
                                        +(49) 36739 593929
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><span className="fa fa-envelope"/>&nbsp;E-Mail</th>
                                        <td>
                                        <a href="mailto:info@bahn-hofladen.de">info@bahn-hofladen.de</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><span className="fa fa-globe"/>&nbsp;Internet</th>
                                        <td>
                                            <a href="https://www.bahn-hofladen.de">https://www.bahn-hofladen.de</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><span className="fa fa-facebook"/>&nbsp;Facebook</th>
                                        <td>
                                            <a href="https://www.facebook.com/bahnhofladeneg">https://www.facebook.com/bahnhofladeneg</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><span className="fa fa-instagram"/>&nbsp;Instagram</th>
                                        <td>
                                            <a href="https://www.instagram.com/bahnhofladen__rottenbach">@bahnhofladen__rottenbach</a>
                                        </td>
                                    </tr>
                                </tbody>                                    
                                </table>
                            </div>   
                        </div>                            
                    </div>          
        </section>           
      );  
    }
  }