import React, { Component } from 'react';
import "./HeaderBanner.css";

export default class HeaderBanner extends Component {
    render() {
      return (
        <div className="row headerbanner" role="banner">
            <div className="col-sm headerbanner-item">
                <span className="fa fa-phone"/>&nbsp;<a href="tel:+49 36739 593929">+49 36739 593929</a>    
            </div>            
            <div className="col-sm headerbanner-item">
                <span className="fa fa-envelope"/>&nbsp;<a href="mailto:info@bahn-hofladen.de">info@bahn-hofladen.de</a> 
            </div>      
            <div className="col-sm headerbanner-item">                
                <span className="fa fa-map-signs"/>&nbsp;<a href="https://goo.gl/maps/VSzwZx6fR78TSbuv5">Karte</a>
            </div>     
        </div>      
      );  
    }
  }