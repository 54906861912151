import React from 'react';
import ReactDOM from 'react-dom';

import HomeScreen from "./utilities/pwa/HomeScreen";
import App from './App';

////////////////////////////////////////////////////////////
// Add to Homescreen
////////////////////////////////////////////////////////////
var pwaAppID = "de.bahn-hofladen";
HomeScreen.register(pwaAppID);	

////////////////////////////////////////////////////////////
// Register Service worker
////////////////////////////////////////////////////////////
if (navigator.serviceWorker) {
    navigator.serviceWorker.register('sw.js', {scope: '.'})
        .then(function (registration) {
            console.log(registration);
        })
        .catch(function (e) {
            console.error(e);
        })
} else {
    console.log('Service Worker is not supported in this browser.');
}

////////////////////////////////////////////////////////////
// Render React
////////////////////////////////////////////////////////////
ReactDOM.render(<App />, document.getElementById('root'));