import React, { Component } from 'react';

import "./OfferSection.css";

export default class OfferSection extends Component {
    render() {
      return (
        <section className="offersection">     
            <a id="angebot" href="."> </a>    
            <h1>Was wir bieten</h1> 
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <ul>
                    <li>Ladengeschäft für den täglichen Bedarf</li>
                    <li>Kaffee und Kuchen, Eisspezialitäten</li>
                    <li>Imbiss / Bistro zum Verweilen</li>
                    <li>Info-Point mit Bürgertreff</li>
                    <li>Geschenkideen</li>                
                  </ul> 
                </div>
                <div className="col-sm">
                  <ol>
                    <li>Lebensmittel des täglichen Bedarfs, Obst und Gemüse</li>
                    <li>Milchprodukte aus regionaler Molkerei</li>
                    <li>Regionale Wurstwaren verschiedener Fleischereien</li>
                    <li>Zahlreiche regionale Lebensmittel, Kräuterspezialitäten</li>
                    <li>Getränke aus regionaler Herstellung</li>
                    <li>Brot und Brötchen frisch aus der Bäckerei</li>
                    <li>Zahlreiche Kaffeespezialitäten und Thüringer Kuchen</li>
                    <li>Wechselnde Angebote im Bistro, Mitagessen, Eistheke</li>
                    <li>Briefmarken, Müllmarken, öff. Bücherregal, freies W-LAN</li>
                    <li>Multifunktionales Büro zur bedarfsweisen Nutzung</li>
                    <li>Geschenkvariationen, Gutscheine, Souvenirs, Eintrittskarten</li>
                    <li>ec-Zahlung u. Geldauszahlung beim Einkauf möglich* <small>*begrenzter Betrag</small></li>
                  </ol> 
                </div>
               </div>
               <p>Parkplätze - Sommerterasse - Freisitze</p>
               <p>Barrierefreier Zugang <small>(bahnseitig)</small> behindertengerechtes WC</p>
            </div>                      
        </section>      
      );  
    }
  }