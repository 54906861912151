import React, { Component } from 'react';

import "./StationOfYear.css";

export default class HeroUnit extends Component {
    render() {
      return (
        <section className="stationOfYear">     
            <a id="stationOfTheYear" href="."> </a>    
            
            <img src="images/images/sonderpreis.png" alt="" />
            <p>Bahnhof des Jahres ist eine Auszeichnung, die seit 2004 jährlich durch das Schienenbündnis Allianz pro Schiene an meist zwei Bahnstationen 
vergeben wird. Seit 2012 wird meist zusätzlich ein Bahnhof in einer Tourismusregion ausgelobt. Der Bahnhof Rottenbach erhielt den <b>Sonderpreis im Wettbewerb Bahnhof des Jahres 2020</b>.
</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BogAsSelghw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <br/>
            <p>
               Lesen sie mehr: <a href="https://www.allianz-pro-schiene.de/wettbewerbe/bahnhof-des-jahres/bahnhof-rottenbach/">Bahnhof Rottenbach - Bürgertreffpunkt und Mittelpunkt"></a>

            </p>
            <br/>
        </section>        
      );  
    }
  }