import Transcript from "../Transcript";

class HomeScreen {
		
	// Register the given unique appID 	 	 
	static register(appID) {        		 
		 Transcript.show("Calling register");
		 var saved = localStorage.getItem(appID);	
		 if(saved && saved.added) { return;}
		 
		 Transcript.show("Install");
		 let deferredPrompt;

		 window.addEventListener('beforeinstallprompt', (e) => {
		   // Stash the event so it can be triggered later.
		   deferredPrompt = e;
		   Transcript.show("registered onBefore;");
		   	console.log(deferredPrompt);	  
		 });

		 window.addEventListener('appinstalled', (evt) => {
			console.log('Application was installed');
		  });

		  Transcript.show("Registration was done");
	 }	 
}

// eslint-disable-next-line
HomeScreen.deferredPrompt = 1;

export default HomeScreen;