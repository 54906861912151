import React, { Component } from 'react';
import "./MenuCard.css";
import "./speiseplan";
import { speiseplan } from './speiseplan';

export default class MenuCard extends Component {
    render() {
      
      return (
        <section className="menucard">
            <h1>Unsere Speisekarte</h1>
            <h2>Saisonale Highlights - frisch aus der Region</h2>
            <hr/>
            <h3>Vom {speiseplan.periode}</h3>
            <h5>{speiseplan.zusatz}</h5>
            <div className="menucard-item" id="montag" style= {{ display: speiseplan.montag.sichtbar ? 'block' : 'none'}}>
              <div class="mealday">Montag</div>
              <div>
                  <span class="meal">{speiseplan.montag.gericht}</span><br/>
                  <span class="meal-addition">{speiseplan.montag.beigabe}</span>
                  <span class="price">{speiseplan.montag.preis}</span>
              </div>            
            </div>
            <div className="menucard-item" id="dienstag" style= {{ display: speiseplan.dienstag.sichtbar ? 'block' : 'none'}}>
              <div class="mealday">Dienstag</div>
              <div>
                  <span class="meal">{speiseplan.dienstag.gericht}</span><br/>
                  <span class="meal-addition">{speiseplan.dienstag.beigabe}</span>
                  <span class="price">{speiseplan.dienstag.preis}</span>
              </div>            
            </div>
            <div className="menucard-item" id="mittwoch" style= {{ display: speiseplan.mittwoch.sichtbar ? 'block' : 'none'}}>
              <div class="mealday">Mittwoch</div>
              <div>
                  <span class="meal">{speiseplan.mittwoch.gericht}</span><br/>
                  <span class="meal-addition">{speiseplan.mittwoch.beigabe}</span>
                  <span class="price">{speiseplan.mittwoch.preis}</span>
              </div>            
            </div>
            <div className="menucard-item" id="donnerstag" style= {{ display: speiseplan.donnerstag.sichtbar ? 'block' : 'none'}}>
              <div class="mealday">Donnerstag</div>
              <div>
                  <span class="meal">{speiseplan.donnerstag.gericht}</span><br/>
                  <span class="meal-addition">{speiseplan.donnerstag.beigabe}</span>
                  <span class="price">{speiseplan.donnerstag.preis}</span>
              </div>            
            </div>
            <div className="menucard-item" id="freitag" style= {{ display: speiseplan.freitag.sichtbar ? 'block' : 'none'}}>
              <div class="mealday">Freitag</div>
              <div>
                  <span class="meal">{speiseplan.freitag.gericht}</span><br/>
                  <span class="meal-addition">{speiseplan.freitag.beigabe}</span>
                  <span class="price">{speiseplan.freitag.preis}</span>
              </div>            
            </div>
            <h4>Vorbestellung möglich im Laden oder unter <a href="tel:+4936739593929">036739/593929</a> oder <a href="tel:+4915238955279">0152/38955279</a></h4>
        </section>
      ); 
    }
}