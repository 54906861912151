import React, { Component } from 'react';
import "./App.css";
import Header from './components/structure/banner/Header';
import MenuCard from './components/structure/banner/menu/MenuCard';
import HeroUnit from './components/structure/content/HeroUnit';
import OfferSection from './components/structure/content/OfferSection';
import StationOfYear from './components/structure/content/StationOfYear';
import Imprint from './components/structure/footer/Imprint';
import ServiceSection from './components/structure/content/ServiceSection';
import ContactSection from './components/structure/content/ContactSection';

export default class App extends Component {
    render() {
      return (
        <div className="container-fluid px-0">
          <Header></Header>
          <HeroUnit></HeroUnit>
          
          <StationOfYear></StationOfYear>
          <OfferSection></OfferSection>
          <MenuCard></MenuCard>
          <ServiceSection></ServiceSection>
          <ContactSection></ContactSection>
          <Imprint></Imprint>
        </div>
      );  
    }
  }