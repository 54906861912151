export const speiseplan = {    
    periode:  "11.11. - 15.11.2024",
    
    zusatz:  "11:30 Uhr - 13:30 Uhr",

    montag: {
        sichtbar: true,
        gericht: "Hausgemachter Kohlrübeneintopf",
        beigabe: "",
        preis: "7,00 €"
    },

    dienstag: {
        sichtbar: true,
        gericht: "Eier in Senfsoße",
        beigabe: "mit Kartoffeln",
        preis: "8,90 €"
    },


    mittwoch: {
        sichtbar: true,
        gericht: "Hackfleisch-Kraut-Pfanne",
        beigabe: "mit Kartoffelpürree",                                   
        preis: "9,90 €"
    },

    donnerstag: {
        sichtbar: true,
        gericht: "Pangasiusfilet",
        beigabe: "mit hausgemachtem Kartoffelsalat",
        preis: "12,90 €"
    },

    freitag: {
        sichtbar: true,
        gericht: "Würstchen",
        beigabe: "mit Kartoffelsalat",
        preis: "7,90 €"
    },
};
