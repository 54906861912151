import React, { Component } from 'react';
import "./NavigationMenu.css";

export default class NavigationMenu extends Component {
    render() {
      return (        
        <ul className="navbar-nav mr-auto"> 
          <li className="nav-item">
              <a className="nav-link" href="#stationOfTheYear">Auszeichnung</a>
          </li>
          <li className="nav-item">
              <a className="nav-link" href="#angebot">Angebot</a>
          </li>          
          <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="." id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Speisekarte
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="#montag">Gesamt</a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#montag" aria-hidden="true">Montag</a>
              <a className="dropdown-item" href="#dienstag" aria-hidden="true">Dienstag</a>          
              <a className="dropdown-item" href="#mittwoch" aria-hidden="true">Mittwoch</a>          
              <a className="dropdown-item" href="#donnerstag" aria-hidden="true">Donnerstag</a>          
              <a className="dropdown-item" href="#freitag" aria-hidden="true">Freitag</a>                    
              </div>
          </li>
          <li className="nav-item"><a className="nav-link" href="#service">Service</a></li>          
          <li className="nav-item"><a className="nav-link" href="#kontakt">Kontakt</a></li>          
          <li className="nav-item"><a className="nav-link" href="#impressum">Impressum</a></li>
        </ul>
      ); 
    }
}