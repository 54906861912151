import React, { Component } from 'react';
import "./NavigationBar.css";
import NavigationMenu from './NavigationMenu';

export default class NavigationBar extends Component {
    render() {
      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light" role="navigation">
            <a className="navbar-brand" href="."><img src="./images/icons/icon-72x72.png" alt=""/>&nbsp;&nbsp;Bahn-Hofladen</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <NavigationMenu></NavigationMenu>
            </div>
        </nav>
      ); 
    }
}